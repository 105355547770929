import clsx from "clsx";
import { getFrequencyValue } from "utils/helpers";

import styles from "./GuideItems.module.scss";

const Portfolios = () => (
  <div className={clsx(styles.root, styles.portfolios)}>
    <div className={styles.title}>
      <h1>How to Add a Portfolio</h1>
      <p>
        Select the portfolio/s you wish to add and click on the {"'"}Add{"'"} button. The selected portfolio/s will
        appear in a new Excel tab.
      </p>
    </div>
    <img alt="portfolios" src="/assets/portfolioGuide.png" />
  </div>
);

const Products = () => (
  <div className={clsx(styles.root, styles.products)}>
    <div className={styles.title}>
      <h1>How to Add Products</h1>
      <p>
        The Add Products screen allows you to add a selection of individual products. The product/s{" "}
        <strong>will be added from the cell on the sheet with the focus to the right.</strong>
      </p>
    </div>
    <img alt="products" src="/assets/productsGuide.png" />
    <p>
      You need to select a preferred data provider for swaps. The blue line refers to our current data provider, and the
      red refers to our new data provider. If you have any questions, please contact us at{" "}
      <a href="mailto:success@spartacommodities.com">success@spartacommodities.com</a>.
    </p>
  </div>
);

const Formulas = () => (
  <div className={clsx(styles.root, styles.formulas)}>
    <div className={styles.title}>
      <h1>How to work with Formulas</h1>
      <p>It is possible to get the forward price of a product for a particular month directly using the formulas.</p>
    </div>
    <img alt="formulas" src="/assets/formulasGuide.png" />
    <p>
      The {'"'}Product name{'"'} and {'"'}Tenor{'"'} parameters can be modified to get the product desired.
    </p>
    <div className={clsx(styles.rectangle, styles.green, styles.mt9)}>
      {'"'}NAME:name of the product + unit{'"'}.
      <br />
      I.e.: {'"'}NAME:ICE GO Swap $/mt{'"'}.
    </div>
    <div className={clsx(styles.rectangle, styles.orange)}>
      TENOR:month.
      <br />
      I.e.: {'"'}TENOR:BOM{'"'} (current month),
      <wbr />
      {'"'}TENOR:M01{'"'}, etc.
    </div>
  </div>
);

const Performance = () => (
  <div className={clsx(styles.root, styles.performance)}>
    <div className={styles.title}>
      <h1>How to change the frequency of updates</h1>
      <p>
        Running large data models or multiple add-ins could lead to performance degradation. Sparta for Excel enables
        you to reduce the frequency of updates as required to maintain system stability or improve performance. The
        frequency of updates can be set in the main ribbon menu above, next to the “Add Products” button.
      </p>
    </div>

    <div className={styles.blocks}>
      <div>
        <h2>Live</h2>
        <p>This option updates data in real-time.</p>
        <img
          style={{
            width: "147px",
            height: "32px",
          }}
          alt="products"
          src="/assets/guide-performance-live.png"
        />
      </div>

      <div>
        <h2>Recommended</h2>
        <p>
          The update frequency for this option is every {getFrequencyValue("recommended", global.Sparta.user, "s")}{" "}
          seconds. We recommend this option for standard sheets.
        </p>
        <img
          style={{
            width: "147px",
            height: "32px",
          }}
          alt="products"
          src="/assets/guide-performance-recommended.png"
        />
      </div>
      <div>
        <h2>Idle</h2>
        <p>
          The update frequency for this option is every {getFrequencyValue("idle", global.Sparta.user, "s")} seconds. We
          recommend this option when running large data models or multiple add-ins.
        </p>
        <img
          style={{
            width: "147px",
            height: "32px",
          }}
          alt="products"
          src="/assets/guide-performance-idle.png"
        />
      </div>
    </div>
  </div>
);
export const GuideItems = [
  {
    itemKey: "portfolios",
    headerText: "Portfolios",
    children: <Portfolios />,
  },
  {
    itemKey: "products",
    headerText: "Products",
    children: <Products />,
  },
  {
    itemKey: "formulas",
    headerText: "Formulas",
    children: <Formulas />,
  },
  {
    itemKey: "performance",
    headerText: "Performance",
    children: <Performance />,
  },
] as const;

import { FC } from "react";
import { FontIcon, Text } from "@fluentui/react";

import { Filters } from "../../hooks";

import styles from "./FilterList.module.scss";

type Props = {
  appliedFilters: Filters | undefined;
  onRemoveFilter: (removedFilterKey: string) => void;
};

export const FilterList: FC<Props> = ({ appliedFilters, onRemoveFilter }) => {
  const filtersWithValue = Object.entries(appliedFilters ?? {})
    .filter(({ 1: filter }) => filter.value)
    .sort(([, { order: orderA }], [, { order: orderB }]) => orderA - orderB);

  if (!filtersWithValue.length) return null;

  return (
    <div className={styles.root}>
      {filtersWithValue.map(([key, { title, value }]) => (
        <div className={styles.indicator} key={key}>
          <FontIcon iconName="Cancel" className={styles.icon} onClick={() => onRemoveFilter(key)} />
          <Text>
            {title} - {value}
          </Text>
        </div>
      ))}
    </div>
  );
};

import useTaskPaneContext from "../taskpane/context";
import type { LaunchDarklyMap } from "../types";

export const useFeatureFlag = <FeatureFlag extends keyof LaunchDarklyMap>(
  flag: FeatureFlag
): LaunchDarklyMap[FeatureFlag] | undefined => {
  const { user } = useTaskPaneContext();
  const featureFlag = user?.launchDarklyFF.find(({ name }) => name === flag);

  return featureFlag?.value as LaunchDarklyMap[FeatureFlag] | undefined;
};

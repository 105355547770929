import React, { useState } from "react";
import {
  Checkbox,
  FontIcon,
  MessageBarType,
  Pivot,
  PivotItem,
  Spinner,
  SpinnerSize,
  Text,
  Toggle,
} from "@fluentui/react";
import clsx from "clsx";
import {
  CollapseList,
  Footer,
  Link,
  Logout,
  MessageBar,
  OriginTabs,
  PrimaryButton,
  SearchInput,
} from "taskpane/components";
import { useFeatureFlag } from "utils/useFeatureFlag";

import { UpdateFrequencyMessage } from "../../components/UpdateFrequencyMessage";
import { ApplyFilters } from "./components/ApplyFilters/ApplyFilters";
import CheckboxLabel from "./components/CheckboxLabel";
import { FilterList } from "./components/FilterList/FilterList";
import { Filters, useProductsData, useProductsForm } from "./hooks";
import {
  trackDownloadProduct,
  trackErrorAddProductScreen,
  trackErrorDownloadingProduct,
  trackMaxNumberOfProducts,
  trackRetryErrorAddProductScreen,
} from "./segment";
import { searchByFilters } from "./utils";

import styles from "./Products.module.scss";

export const Products = () => {
  const [selectedOrigins, setSelectedOrigins] = useState<Record<string, string | undefined>>({});
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
  const [showUpdateFrequencyInfo, setShowUpdateFrequencyInfo] = useState(false);

  const [modifyingFilters, setModifyingFilters] = useState<{ key: string; filters: Filters }>();

  const toggleGroup = (group: string, show: boolean) => {
    if (show) {
      setExpandedGroups([...expandedGroups, group]);
    } else {
      setExpandedGroups(expandedGroups.filter((g) => g !== group));
    }
  };

  const {
    data,
    dataError,
    dataProvider,
    filterError,
    filterText,
    filtersState,
    providers: providersFromData,
    setDataProvider,
    updateFilterText,
    updateFilters,
  } = useProductsData();

  const {
    cleanError,
    error,
    isDownloading,
    isProductChecked,
    onClickCheckbox,
    onClickAdd,
    maxLimitReached,
    selected,
    selectedNumber,
    toggleDateColumn,
    withDateColumn,
  } = useProductsForm();

  const isOBInExcel = !!useFeatureFlag("fe_excel_ob");

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <Text className={styles.title}>Add Product</Text>
          {showUpdateFrequencyInfo && <UpdateFrequencyMessage onDismiss={() => setShowUpdateFrequencyInfo(false)} />}

          {maxLimitReached && (
            <MessageBar track={trackMaxNumberOfProducts}>
              Info: You have reached the maximum number of 40 products per download. Please complete the current
              download and start another to add more products.
            </MessageBar>
          )}

          {error && (
            <MessageBar onDismiss={cleanError} track={trackErrorDownloadingProduct} type={MessageBarType.error}>
              Error: Product download failed, please try again. If the error persists, please contact
              success@spartacommodities.com
            </MessageBar>
          )}

          {dataError && dataError.type !== "ERR_NETWORK" && (
            <MessageBar
              actions={
                dataError.type === "NETWORK" && data === undefined ? (
                  <Link
                    className={styles.retry}
                    onClick={() => {
                      trackRetryErrorAddProductScreen();
                      window.location.reload();
                    }}
                  >
                    Retry
                  </Link>
                ) : undefined
              }
              onDismiss={cleanError}
              track={trackErrorAddProductScreen}
              type={MessageBarType.error}
            >
              {dataError.toString()}
            </MessageBar>
          )}
        </div>

        <div className={styles.container}>
          {!data?.categories?.length && !filterError && (
            <div className={styles.spinner}>
              <Spinner label="Retrieving Data..." size={SpinnerSize.large} />
            </div>
          )}
          {isDownloading && (
            <div className={styles.spinner}>
              <Spinner label="Downloading Data..." size={SpinnerSize.large} />
            </div>
          )}

          <SearchInput value={filterText} error={filterError} onChange={updateFilterText} />

          <Pivot className={styles.pivot} styles={{ root: styles.pivotRoot, itemContainer: styles.itemContainer }}>
            {data?.categories?.map((category) => {
              const {
                disabled: categoryDisabled,
                id: categoryId,
                name: categoryName,
                order: categoryOrder,
                productGroups,
                total: categoryTotal,
              } = category;

              const providers = isOBInExcel
                ? providersFromData[category.name as keyof typeof providersFromData] ?? []
                : [];

              const key = String(categoryId);

              return (
                <PivotItem
                  key={key}
                  itemKey={key}
                  headerText={`${categoryName} ${
                    categoryTotal && filterText.length && !filterError ? `(${categoryTotal})` : ""
                  }`}
                >
                  <div className={styles.content}>
                    {categoryDisabled && (
                      <MessageBar className={styles.contactSales} type={MessageBarType.warning}>
                        New Products: To unlock data please contact sales
                      </MessageBar>
                    )}

                    {providers?.length > 1 && (
                      <div className={styles.providerSelectorRoot}>
                        <span>Provider</span>
                        <div className={styles.providerSelectorButtons}>
                          {providers.map((provider) => (
                            <button
                              key={provider}
                              data-provider={provider}
                              data-selected={dataProvider === provider}
                              type="button"
                              onClick={() => setDataProvider(provider)}
                              className={styles.providerSelectorColor}
                            >
                              <div />
                            </button>
                          ))}
                        </div>
                      </div>
                    )}

                    <div>
                      {productGroups.map(
                        ({
                          name: groupName,
                          total,
                          id: groupId,
                          order: groupOrder,
                          products,
                          origins,
                          showAllOrigins,
                          filters,
                        }) => {
                          const key = `${categoryId}-${groupId}`;
                          const selectedTab = selectedOrigins[key] ?? (showAllOrigins ? "All" : origins[0]);

                          const filtersKey = `${key}-${selectedTab}`;
                          const appliedFilters = filtersState[filtersKey];

                          return (
                            <CollapseList
                              disabled={categoryDisabled}
                              isExpanded={expandedGroups.includes(key)}
                              key={groupId}
                              onToggle={(show) => toggleGroup(key, show)}
                              headerChildren={
                                filters && (
                                  <FontIcon
                                    iconName={
                                      Object.values(appliedFilters ?? {}).some(({ value }) => !!value)
                                        ? "FilterSolid"
                                        : "Filter"
                                    }
                                    style={{ marginLeft: "auto" }}
                                    onClick={(ev) => {
                                      setModifyingFilters({
                                        key: filtersKey,
                                        filters:
                                          appliedFilters ??
                                          Object.entries(filters).reduce(
                                            (res, [key, rest]) => ({
                                              ...res,
                                              [key]: {
                                                ...rest,
                                                value: undefined,
                                              },
                                            }),
                                            {}
                                          ),
                                      });
                                      ev.stopPropagation();
                                    }}
                                  />
                                )
                              }
                              title={`${groupName} ${total && filterText.length && !filterError ? `(${total})` : ""}`}
                            >
                              <OriginTabs
                                showAllOrigins={showAllOrigins}
                                origins={origins}
                                selectedTab={selectedTab}
                                onChange={(newTab) => setSelectedOrigins((prev) => ({ ...prev, [key]: newTab }))}
                              >
                                {(originName) => {
                                  const filtersTabKey = `${key}-${typeof originName === "string" ? originName : "All"}`;
                                  const appliedFiltersForTab = filtersState[filtersTabKey];

                                  return (
                                    <>
                                      <FilterList
                                        appliedFilters={appliedFiltersForTab}
                                        onRemoveFilter={(removedFilterKey) => {
                                          if (appliedFiltersForTab) {
                                            const { [removedFilterKey]: removedFilter } = appliedFiltersForTab;
                                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                            const { value, ...rest } = removedFilter;

                                            updateFilters(filtersTabKey, {
                                              ...appliedFiltersForTab,
                                              [removedFilterKey]: {
                                                ...rest,
                                                value: undefined,
                                              },
                                            });
                                          }
                                        }}
                                      />
                                      <ul>
                                        {searchByFilters(
                                          appliedFiltersForTab,
                                          originName
                                            ? products.filter((p) => {
                                                if (Array.isArray(p.origins) && p.origins.length > 0) {
                                                  return p.origins.some((o) => o === originName);
                                                }

                                                return p.origin === originName;
                                              })
                                            : products
                                        ).map(({ shortName, unit, code, dataProvider }) => {
                                          const checked = isProductChecked(code);
                                          const disabled = maxLimitReached && !checked;

                                          return (
                                            <li key={code}>
                                              <Checkbox
                                                styles={{ label: styles.label, text: styles.text }}
                                                label={shortName}
                                                checked={checked}
                                                disabled={disabled}
                                                onChange={() =>
                                                  onClickCheckbox({
                                                    categoryOrder,
                                                    groupOrder,
                                                    productLabel: `${shortName} (${unit})`,
                                                    code,
                                                    dataProvider,
                                                  })
                                                }
                                                onRenderLabel={
                                                  unit
                                                    ? () => (
                                                        <CheckboxLabel
                                                          label={shortName}
                                                          unit={unit}
                                                          styles={{
                                                            text: clsx(styles.text, disabled && styles.disabled),
                                                            unit: styles.unit,
                                                          }}
                                                        />
                                                      )
                                                    : undefined
                                                }
                                              />
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </>
                                  );
                                }}
                              </OriginTabs>
                            </CollapseList>
                          );
                        }
                      )}
                    </div>
                  </div>
                </PivotItem>
              );
            })}
          </Pivot>

          <Footer>
            <Logout page="Add Product" />
            <div className={styles.actions}>
              <Toggle
                styles={{ root: styles.toggle, label: styles.toggleLabel }}
                label="Month Column"
                onChange={toggleDateColumn}
                checked={withDateColumn}
              />

              <PrimaryButton
                disabled={selectedNumber === 0}
                onClick={() => {
                  trackDownloadProduct(
                    withDateColumn,
                    selected.map((s) => s.productLabel)
                  );
                  setShowUpdateFrequencyInfo(true);
                  onClickAdd();
                }}
              >
                Add
              </PrimaryButton>
            </div>
          </Footer>
        </div>
      </div>
      <ApplyFilters
        filtersKey={modifyingFilters?.key || ""}
        filters={modifyingFilters?.filters}
        categories={data?.categories}
        onAccept={updateFilters}
        onClose={() => setModifyingFilters(undefined)}
      />
    </>
  );
};

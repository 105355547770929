import { useState } from "react";
import { injectProducts } from "commands/commands";
import { Product } from "services/liveCurves/types";

type ProductItem = Pick<Product, "code" | "dataProvider"> & {
  categoryOrder: number;
  groupOrder: number;
  productLabel: string;
  code: string;
};

const sorter = (a: ProductItem, b: ProductItem) => {
  if (a.categoryOrder === b.categoryOrder) {
    if (a.groupOrder === b.groupOrder) {
      return a.productLabel < b.productLabel ? -1 : 1;
    }

    return a.groupOrder < b.groupOrder ? -1 : 1;
  }

  return a.categoryOrder < b.categoryOrder ? -1 : 1;
};

export const useProductsForm = () => {
  const [selected, setSelected] = useState<ProductItem[]>([]);
  const [withDateColumn, setWithDateColumn] = useState(false);
  const [error, setError] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const isProductChecked = (productCode: string) => selected.some(({ code }) => productCode === code);

  const onClickCheckbox = (product: ProductItem) => {
    const existingItem = selected.findIndex(({ code }) => code === product.code);

    if (existingItem !== -1) {
      // Uncheck
      setSelected([...selected.filter((_, idx) => idx !== existingItem)]);
    } else {
      // Check
      setSelected([...selected, product]);
    }
  };

  const onClickAdd = async () => {
    let loaderTimeout: NodeJS.Timeout | undefined = undefined;

    try {
      setError(false);
      const sorted = [...selected].sort(sorter);

      loaderTimeout = setTimeout(() => setIsDownloading(true), 2000);

      await injectProducts(sorted, withDateColumn);

      clearTimeout(loaderTimeout);
      setIsDownloading(false);

      setSelected([]);
      setWithDateColumn(false);
    } catch {
      clearTimeout(loaderTimeout);
      setIsDownloading(false);

      setError(true);
    }
  };

  return {
    cleanError: () => setError(false),
    error,
    isDownloading,
    isProductChecked,
    onClickCheckbox,
    onClickAdd,
    selected,
    maxLimitReached: selected.length === 40,
    selectedNumber: selected.length,
    toggleDateColumn: () => setWithDateColumn((prev) => !prev),
    withDateColumn,
  };
};
